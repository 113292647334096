// extracted by mini-css-extract-plugin
export const form = "FormStep2-module--form--KOl4B";
export const contactContainer = "FormStep2-module--contactContainer--1vAME";
export const buttonsContainer = "FormStep2-module--buttonsContainer--8hKBK";
export const urgencyButtonsContainer = "FormStep2-module--urgencyButtonsContainer--2iZIz";
export const questionaryButton = "FormStep2-module--questionaryButton--319pu";
export const checkIcon = "FormStep2-module--checkIcon--1cZgL";
export const active = "FormStep2-module--active--aX07b";
export const urgencyWarning = "FormStep2-module--urgencyWarning--14rBT";
export const submitButton = "FormStep2-module--submitButton--2UgQJ";
export const formDisabled = "FormStep2-module--formDisabled--3ZgCd";
export const formReady = "FormStep2-module--formReady--2Gkyu";
export const formSubmitting = "FormStep2-module--formSubmitting--2kdH7";
export const formSubmitted = "FormStep2-module--formSubmitted--3VAjf";
export const formMessage = "FormStep2-module--formMessage--2JzSl";
export const formMessageSuccess = "FormStep2-module--formMessageSuccess--1ldeo";
export const formMessageError = "FormStep2-module--formMessageError--j4TIh";