import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useForm, GenericField } from '../utils/forms';

import InputField from './InputField';

import * as styles from './FormStep1.module.scss';
import { COUNTRY_CODE_TO_COUNTRY_NAME } from '../constants';

const customStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    fontFamily: 'Poppins, sans-serif;',
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#e7e3e1' : 'white',
    cursor: 'pointer',
    color: '#4d4d4d',
    padding: 8,
    fontSize: '14px',
    fontFamily: 'Poppins, sans-serif;',
    fontWeight: state.isSelected ? '500' : '400',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Poppins, sans-serif;',
    fontSize: '14px',
    color: '#000',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    fontFamily: 'Poppins, sans-serif;',
    fontSize: '14px',
    color: '#ffffff',
    backgroundColor: '#0f1421',
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    color: '#ffffff',
    ':hover': {
      backgroundColor: '#777777',
    },
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: '#ffffff',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 0 transparent',
    borderColor: '#fff',
    '&:hover': {
      borderColor: '#fff',
    },
    padding: 2,
    opacity: state.isDisabled ? '0.8' : '1',
  }),
};
interface FormStep1Props {
  fieldsByName: {
    readonly name: GenericField<string>;
    readonly email: GenericField<string>;
    readonly phone: GenericField<string>;
    readonly nationality: GenericField<string>;
    readonly residentCountry: GenericField<string>;
  };
  submitForm: () => Promise<boolean>;
}

const FormStep1 = ({ fieldsByName, submitForm }: FormStep1Props): React.ReactElement => {
  const { t } = useTranslation();

  const {
    getFieldProps,
    onFieldUnfocus,
    renderSubmitButton,
    renderFormMessage,
    submitState,
  } = useForm({
    fieldsByName,
    onSubmit: submitForm,
    translateFunction: key => {
      return {
        'form.required_field_error': t(
          'form.validations.required_field_error',
          'This field is required.',
        ),
        'form.invalid_email_error': t(
          'form.validations.invalid_email_error',
          'Invalid email format.',
        ),
        'form.network_error': t(
          'form.validations.network_error',
          'Network failed to send your request. Please, check your connection and try again.',
        ),
        'form.unknown_error': t(
          'form.validations.unknown_error',
          'An unknown error has occurred. Please, try again.',
        ),
        'form.success_message': t(
          'form.validations.success_message',
          'Thanks for taking the time to fill out this information. One of our associates will reach out shortly.',
        ),
      }[key];
    },
  });

  const countriesOptions = Object.entries(COUNTRY_CODE_TO_COUNTRY_NAME).map(([value, label]) => ({
    value,
    label,
  }));

  return (
    <form name="contact-form" data-netlify="true" className={styles.form}>
      <div className={styles.inputContainer}>
        <label className={styles.label} htmlFor="name-input">
          {t('form_step_1.name', 'Name')}
        </label>
        <InputField
          containerClass={styles.containerClass}
          className={styles.input}
          type="text"
          id="name-input"
          name="name"
          helperTextClass={styles.helperText}
          {...getFieldProps(fieldsByName.name)}
        />
      </div>

      <div className={styles.inputContainer + ' ' + styles.emailInputContainer}>
        <label className={styles.label} htmlFor="email-input">
          {t('form_step_1.email', 'Email')}
        </label>
        <InputField
          containerClass={styles.containerClass}
          className={styles.input}
          type="email"
          id="email-input"
          name="email"
          helperTextClass={styles.helperText}
          {...getFieldProps(fieldsByName.email)}
        />
      </div>

      <div className={styles.inputContainer + ' ' + styles.phoneIinputContainer}>
        <label className={styles.label} htmlFor="email-input">
          {t('form_step_1.mobile_phone', 'Mobile phone (with country code)')}
        </label>
        <InputField
          containerClass={styles.containerClass}
          className={styles.input}
          type="phone"
          id="phone-input"
          name="phone"
          helperTextClass={styles.helperText}
          {...getFieldProps(fieldsByName.phone)}
        />
      </div>

      <div className={styles.nationalityAndResidentCountryContainer}>
        <div className={styles.inputContainer}>
          <label className={styles.label} htmlFor="nationality-input">
            {t('form_step_1.nationality', 'Nationality')}
          </label>
          <div className={styles.selectWrapper}>
            <Select
              name="nationality"
              menuPlacement="top"
              styles={customStyles}
              options={countriesOptions}
              value={
                countriesOptions.find(option => option.value === fieldsByName.nationality.value) ||
                undefined
              }
              onChange={currentOption => {
                fieldsByName.nationality.setValue(currentOption?.value || '');
                fieldsByName.nationality.setError('');
              }}
              onBlur={() => onFieldUnfocus(fieldsByName.nationality)}
              blurInputOnSelect={false}
              isDisabled={submitState === 'submitted'}
            />
            <div
              className={!!fieldsByName.nationality.error ? styles.helperText : ''}
              style={{
                visibility: !fieldsByName.nationality.error ? 'hidden' : undefined,
                marginTop: 8,
              }}
            >
              {fieldsByName.nationality.error || 'x'}
            </div>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <label className={styles.label} htmlFor="residentCountry-input">
            {t('form_step_1.residentCountry', 'Resident country')}
          </label>
          <div className={styles.selectWrapper}>
            <Select
              name="residentCountry"
              menuPlacement="top"
              styles={customStyles}
              options={countriesOptions}
              value={
                countriesOptions.find(
                  option => option.value === fieldsByName.residentCountry.value,
                ) || undefined
              }
              onChange={currentOption => {
                fieldsByName.residentCountry.setValue(currentOption?.value || '');
                fieldsByName.residentCountry.setError('');
              }}
              onBlur={() => onFieldUnfocus(fieldsByName.residentCountry)}
              blurInputOnSelect={false}
              isDisabled={submitState === 'submitted'}
            />
            <div
              className={!!fieldsByName.residentCountry.error ? styles.helperText : ''}
              style={{
                visibility: !fieldsByName.residentCountry.error ? 'hidden' : undefined,
                marginTop: 8,
              }}
            >
              {fieldsByName.residentCountry.error || 'x'}
            </div>
          </div>
        </div>
      </div>

      {renderSubmitButton({
        id: submitState === 'ready' ? 'mkt-submit-button-ready' : '',
        labels: {
          disabled: t('form_step_1.button.send', 'Submit'),
          ready: t('form_step_1.button.send', 'Submit'),
          submitting: t('form_step_1.button.sending', 'Submitting'),
          submitted: t('form_step_1.button.sent', 'Submitted!'),
        },
        btnClasses: {
          common: styles.submitButton,
          disabled: styles.formDisabled,
          ready: styles.formReady,
          submitting: styles.formSubmitting,
          submitted: styles.formSubmitted,
        },
        iconClasses: {
          common: 'fas',
          disabled: '',
          ready: '',
          submitting: 'fa-spinner fa-spin',
          submitted: 'fa-check',
        },
      })}
      {renderFormMessage({
        styles: {
          formMessage: styles.formMessage,
          formMessageSuccess: styles.formMessageSuccess,
          formMessageError: styles.formMessageError,
        },
      })}
    </form>
  );
};

export default FormStep1;
