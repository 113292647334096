/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { replaceNewLinesWithBr } from '../utils/sanity';
import FormStep1 from './FormStep1';
import FormStep2 from './FormStep2';

import * as styles from './FormBox.module.scss';
import { useFormField } from '../utils/forms';
import {
  COUNTRY_CODE_TO_COUNTRY_NAME,
  PageTheme,
  PHONE_COUNTRY_CODE_BY_COUNTRY,
} from '../constants';
import UnqualifiedForm from './UnqualifiedForm';

interface FormBoxProps {
  title: string;
  description: string;
  lang: string;
  pageTheme: PageTheme;
}

const FormBox = ({ title, description, lang, pageTheme }: FormBoxProps): React.ReactElement => {
  const { t } = useTranslation();

  const [formStep, setFormStep] = useState<
    'formStep1' | 'formStep2' | 'unqualifiedForm' | 'qualifiedForm'
  >('formStep1');
  const clickOnForm2 = useRef(false);
  const clickOnUnqualifiedForm = useRef(false);

  if (PHONE_COUNTRY_CODE_BY_COUNTRY[lang] === undefined) {
    Sentry.captureException(`Missing country ${lang} on PHONE_COUNTRY_CODE_BY_COUNTRY`);
  }
  const phoneCountryCode = PHONE_COUNTRY_CODE_BY_COUNTRY[lang] || '';

  const form1FieldsByName = {
    name: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
    phone: useFormField<string>(phoneCountryCode, [
      'required',
      phone =>
        phone === phoneCountryCode
          ? t('form.validations.required_field_error', 'This field is required.')
          : null,
    ]),
    nationality: useFormField<string>('', ['required']),
    residentCountry: useFormField<string>('', ['required']),
  } as const;

  const form2FieldsByName = {
    checked__500_000: useFormField<boolean | null>(null, []),
    flexibility: useFormField<boolean | null>(null, []),
    urgency: useFormField<string | null>(null, []),
  } as const;

  const unqualifiedFormFieldsByName = {
    terms: useFormField<string | null>(null, []),
  } as const;

  async function wait(ms) {
    return new Promise(function (resolve, reject) {
      setTimeout(resolve, ms);
    });
  }

  async function submitToHubspot() {
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
    const hubspotData = {
      name: form1FieldsByName.name.value,
      email: form1FieldsByName.email.value,
      phone: form1FieldsByName.phone.value,
      // @ts-ignore
      nationality: COUNTRY_CODE_TO_COUNTRY_NAME[form1FieldsByName.nationality.value!],
      // @ts-ignore
      residentCountry: COUNTRY_CODE_TO_COUNTRY_NAME[form1FieldsByName.residentCountry.value!],
      checked__500_000: form2FieldsByName.checked__500_000.value,
      flexibility: form2FieldsByName.flexibility.value,
      urgency: form2FieldsByName.urgency.value,
      sort_form_button: clickOnForm2.current,
      work_visa: unqualifiedFormFieldsByName.terms.value === 'work_visa',
      d7d2_visa: unqualifiedFormFieldsByName.terms.value === 'd7d2_visa',
      gv_visa: unqualifiedFormFieldsByName.terms.value === 'gv_visa',
      unqual_form_button: clickOnUnqualifiedForm.current,
      country: lang,
      theme: pageTheme,
      queryString: window.location.search.substring(1),
    };
    console.log('Submitting hubspotData:', hubspotData);
    if (form1FieldsByName.name.value === '_TEST_SENTRY_') {
      throw new Error('Test sentry error');
    }
    const resp = await fetch('/.netlify/functions/submit-to-hubspot', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(hubspotData),
    });
    if (resp.status !== 200) {
      if (resp.status === 400) {
        const data = await resp.json();
        if (data.error === 'INVALID_EMAIL') {
          form1FieldsByName.email.setError(
            t('form.validations.invalid_email_error', 'Invalid email format.'),
          );
          return false;
        } else {
          throw new Error('Got response with status code ' + resp.status);
        }
      } else {
        throw new Error('Got response with status code ' + resp.status);
      }
    }

    if ('dataLayer' in window) {
      window.dataLayer.push({
        event: 'LeadSubmit',
        formStep,
        checked500000: form2FieldsByName.checked__500_000.value,
        flexibility: form2FieldsByName.flexibility.value,
      });
      if (
        formStep === 'formStep2' &&
        form2FieldsByName.checked__500_000.value &&
        form2FieldsByName.flexibility.value
      ) {
        window.dataLayer.push({ event: 'QualifiedLeadSubmit' });
      }
    }
    return true;
  }

  return (
    <div className={styles.container + ' ' + styles[formStep]}>
      <span className={styles.fakeAnchor} id="form-fake-anchor"></span>
      <h2>{title}</h2>
      <p className={styles.description}>
        {
          {
            formStep1: replaceNewLinesWithBr(description),
            formStep2: t(
              'form_step_2.title',
              'To help us provide the best solution for you, please answer the following questions:',
            ),
            qualifiedForm: (
              <>
                <Trans
                  i18nKey="qualified_form.title"
                  defaults="Thank you!<br><br>One of our associates will be in touch very soon."
                  components={{
                    br: <br></br>,
                  }}
                />
                <div className={styles.spamWarning}>
                  {t(
                    'qualified_form.spamWarning',
                    'You should now have received an email containing important information about the visa process, if you do not see it please check your spam or junk folder.',
                  )}
                </div>
              </>
            ),
            unqualifiedForm: t('unqualified_form.title', 'Please select what best describes you:'),
          }[formStep]
        }
      </p>

      {formStep === 'formStep1' && (
        <>
          <FormStep1
            fieldsByName={form1FieldsByName}
            submitForm={async () => {
              const success = await submitToHubspot();
              if (success) {
                setFormStep('formStep2');
              }
              return success;
            }}
          ></FormStep1>
          <p className={styles.bottomNote}>
            {t(
              'form.bottom_note',
              'By completing this form you are opting into emails from Pela Terra. You can unsubscribe at any time.',
            )}
          </p>
        </>
      )}

      {formStep === 'formStep2' && (
        <FormStep2
          fieldsByName={form2FieldsByName}
          submitForm={async () => {
            clickOnForm2.current = true;
            const success = await submitToHubspot();
            if (success) {
              if (form2FieldsByName.checked__500_000.value && form2FieldsByName.flexibility.value) {
                setFormStep('qualifiedForm');
              } else {
                setFormStep('unqualifiedForm');
              }
            }
            return success;
          }}
        ></FormStep2>
      )}

      {formStep === 'unqualifiedForm' && (
        <UnqualifiedForm
          fieldsByName={unqualifiedFormFieldsByName}
          submitForm={async () => {
            clickOnUnqualifiedForm.current = true;
            return await submitToHubspot();
          }}
        ></UnqualifiedForm>
      )}
    </div>
  );
};

export default FormBox;
