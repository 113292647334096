import React from 'react';
import '../styles/typography.scss';

import FormBox from '../components/FormBox';
import { graphql, useStaticQuery } from 'gatsby';
import { localize } from '../utils/sanity';
import { getThemeData } from '../utils/functions';
import { LocalizedHeroData } from '../graphql-fragments/sectionFragments';

interface LocalizedData {
  default: LocalizedHeroData;
  citizenship: LocalizedHeroData;
  sustainability: LocalizedHeroData;
}

const FormPage = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      sanityLandingPage {
        heroSection {
          default {
            ...Hero
          }
          citizenship {
            ...Hero
          }
        }
      }
    }
  `);

  const pageTheme = 'citizenship';

  const localizedData: LocalizedData = localize(data.sanityLandingPage.heroSection, ['us']);

  const themeData = getThemeData(localizedData, pageTheme);

  return (
    <FormBox
      title={themeData.formTitle}
      description={themeData.formDescription}
      lang={'xx'}
      pageTheme={pageTheme}
    ></FormBox>
  );
};

export default FormPage;
