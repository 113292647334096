// extracted by mini-css-extract-plugin
export const form = "UnqualifiedForm-module--form--3ZM_j";
export const formNotReady = "UnqualifiedForm-module--formNotReady--cE_Bx";
export const questionaryButton = "UnqualifiedForm-module--questionaryButton--3yJqE";
export const buttonsContainer = "UnqualifiedForm-module--buttonsContainer--VuACd";
export const checkIcon = "UnqualifiedForm-module--checkIcon--3Qcxu";
export const active = "UnqualifiedForm-module--active--1sWUz";
export const submitButton = "UnqualifiedForm-module--submitButton--d1zKL";
export const formDisabled = "UnqualifiedForm-module--formDisabled--2IosH";
export const formReady = "UnqualifiedForm-module--formReady--2LoKE";
export const formSubmitting = "UnqualifiedForm-module--formSubmitting--3-R3j";
export const formSubmitted = "UnqualifiedForm-module--formSubmitted--1d9uO";
export const formMessage = "UnqualifiedForm-module--formMessage--1S5fG";
export const formMessageSuccess = "UnqualifiedForm-module--formMessageSuccess--A7ieH";
export const formMessageError = "UnqualifiedForm-module--formMessageError--VdTXn";
export const spamWarning = "UnqualifiedForm-module--spamWarning--pEV3N";