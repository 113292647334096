import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, GenericField } from '../utils/forms';
import { FaCheck } from 'react-icons/fa';

import * as styles from './UnqualifiedForm.module.scss';

interface UnqualifiedFormProps {
  fieldsByName: {
    readonly terms: GenericField<string | null>;
  };
  submitForm: () => Promise<boolean>;
}

const UnqualifiedForm = ({
  fieldsByName,
  submitForm,
}: UnqualifiedFormProps): React.ReactElement => {
  const { t } = useTranslation();

  const { renderSubmitButton, renderFormMessage, submitState } = useForm({
    fieldsByName,
    onSubmit: submitForm,
    translateFunction: key => {
      return {
        'form.required_field_error': '',
        'form.invalid_email_error': '',
        'form.network_error': t(
          'form.validations.network_error',
          'Network failed to send your request. Please, check your connection and try again.',
        ),
        'form.unknown_error': t(
          'form.validations.unknown_error',
          'An unknown error has occurred. Please, try again.',
        ),
        'form.success_message': t(
          'form.validations.success_message',
          'Thanks for taking the time to fill out this information. One of our associates will reach out shortly.',
        ),
      }[key];
    },
  });

  return (
    <form
      name="contact-form"
      data-netlify="true"
      className={styles.form + (submitState !== 'ready' ? ' ' + styles.formNotReady : '')}
    >
      <div className={styles.buttonsContainer}>
        <div
          className={
            styles.questionaryButton +
            (fieldsByName.terms.value === 'work_visa' ? ' ' + styles.active : '')
          }
          onClick={() => {
            if (submitState === 'ready') {
              fieldsByName.terms.setValue('work_visa');
            }
          }}
        >
          <Trans
            i18nKey="unqualified_form.work_visa.radiobutton"
            defaults="I need a <strong>work visa</strong>."
            components={{
              strong: <strong>_</strong>,
            }}
          />
          <FaCheck className={styles.checkIcon}></FaCheck>
        </div>
        <div
          className={
            styles.questionaryButton +
            (fieldsByName.terms.value === 'd7d2_visa' ? ' ' + styles.active : '')
          }
          onClick={() => {
            if (submitState === 'ready') {
              fieldsByName.terms.setValue('d7d2_visa');
            }
          }}
        >
          <Trans
            i18nKey="unqualified_form.d7d2_visa.radiobutton"
            defaults="I have over <strong>€15,000</strong> in savings and I want to <strong>relocate to Portugal</strong>."
            components={{
              strong: <strong>_</strong>,
            }}
          />
          <FaCheck className={styles.checkIcon}></FaCheck>
        </div>
        <div
          className={
            styles.questionaryButton +
            (fieldsByName.terms.value === 'gv_visa' ? ' ' + styles.active : '')
          }
          onClick={() => {
            if (submitState === 'ready') {
              fieldsByName.terms.setValue('gv_visa');
            }
          }}
        >
          <Trans
            i18nKey="unqualified_form.gv_visa.radiobutton"
            defaults="I want to start the citizenship process without moving to Portugal, and am ready to invest <strong>€500,000</strong>."
            components={{
              strong: <strong>_</strong>,
            }}
          />
          <FaCheck className={styles.checkIcon}></FaCheck>
        </div>
      </div>
      {renderSubmitButton({
        labels: {
          disabled: t('unqualified_form.button.send', 'Confirm'),
          ready: t('unqualified_form.button.send', 'Confirm'),
          submitting: t('unqualified_form.button.sending', 'Sending'),
          submitted: t('unqualified_form.button.sent', 'Sent!'),
        },
        btnClasses: {
          common: styles.submitButton,
          disabled: styles.formDisabled,
          ready: styles.formReady,
          submitting: styles.formSubmitting,
          submitted: styles.formSubmitted,
        },
        iconClasses: {
          common: 'fas',
          disabled: '',
          ready: '',
          submitting: 'fa-spinner fa-spin',
          submitted: 'fa-check',
        },
      })}
      {renderFormMessage({
        styles: {
          formMessage: styles.formMessage,
          formMessageSuccess: styles.formMessageSuccess,
          formMessageError: styles.formMessageError,
        },
      })}
      {submitState === 'submitted' && (
        <div className={styles.spamWarning}>
          {t(
            'unqualified_form.spamWarning',
            'You should now have received an email containing important information about the visa process, if you do not see it please check your spam or junk folder',
          )}
        </div>
      )}
    </form>
  );
};

export default UnqualifiedForm;
