import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, GenericField } from '../utils/forms';
import { FaCheck } from 'react-icons/fa';
import * as styles from './FormStep2.module.scss';
interface FormStep2Props {
  fieldsByName: {
    readonly checked__500_000: GenericField<boolean | null>;
    readonly flexibility: GenericField<boolean | null>;
    readonly urgency: GenericField<string | null>;
  };
  submitForm: () => Promise<boolean>;
}

const FormStep2 = ({ fieldsByName, submitForm }: FormStep2Props): React.ReactElement => {
  const { t } = useTranslation();

  const { renderSubmitButton, renderFormMessage } = useForm({
    fieldsByName,
    onSubmit: submitForm,
    translateFunction: key => {
      return {
        'form.required_field_error': '',
        'form.invalid_email_error': '',
        'form.network_error': t(
          'form.validations.network_error',
          'Network failed to send your request. Please, check your connection and try again.',
        ),
        'form.unknown_error': t(
          'form.validations.unknown_error',
          'An unknown error has occurred. Please, try again.',
        ),
        'form.success_message': t(
          'form.validations.success_message',
          'Thanks for taking the time to fill out this information. One of our associates will reach out shortly.',
        ),
      }[key];
    },
  });

  return (
    <form name="contact-form" data-netlify="true" className={styles.form}>
      <div className={styles.contactContainer}>
        <span>
          <Trans
            i18nKey="form_step_2.checked__500_000.radiobutton"
            defaults="1. I understand there is a minimum investment of <strong>€500000</strong>."
            components={{
              strong: <strong>_</strong>,
            }}
          />
        </span>
        <div className={styles.buttonsContainer}>
          <div
            className={
              styles.questionaryButton +
              (fieldsByName.checked__500_000.value === true ? ' ' + styles.active : '')
            }
            onClick={() => fieldsByName.checked__500_000.setValue(true)}
          >
            {t('form_step_2.checked__500_000.yes_label', 'Yes')}
            <FaCheck className={styles.checkIcon}></FaCheck>
          </div>
          <div
            className={
              styles.questionaryButton +
              (fieldsByName.checked__500_000.value === false ? ' ' + styles.active : '')
            }
            onClick={() => fieldsByName.checked__500_000.setValue(false)}
          >
            {t('form_step_2.checked__500_000.no_label', 'No')}
            <FaCheck className={styles.checkIcon}></FaCheck>
          </div>
        </div>
      </div>
      <div className={styles.contactContainer}>
        <span>
          <Trans
            i18nKey="form_step_2.flexibility.radiobutton"
            defaults="2. I require <strong>flexibility</strong> to live more than half the year <strong>outside</strong> of Portugal."
            components={{
              strong: <strong>_</strong>,
            }}
          />
        </span>
        <div className={styles.buttonsContainer}>
          <div
            className={
              styles.questionaryButton +
              (fieldsByName.flexibility.value === true ? ' ' + styles.active : '')
            }
            onClick={() => fieldsByName.flexibility.setValue(true)}
          >
            {t('form_step_2.flexibility.yes_label', 'Yes')}
            <FaCheck className={styles.checkIcon}></FaCheck>
          </div>
          <div
            className={
              styles.questionaryButton +
              (fieldsByName.flexibility.value === false ? ' ' + styles.active : '')
            }
            onClick={() => fieldsByName.flexibility.setValue(false)}
          >
            {t('form_step_2.flexibility.no_label', 'No')}
            <FaCheck className={styles.checkIcon}></FaCheck>
          </div>
        </div>
      </div>
      <div className={styles.contactContainer}>
        <span>
          <Trans
            i18nKey="form_step_2.urgency.radiobutton"
            defaults="3. How soon do you want to <strong>complete</strong> your <strong>application</strong> for citizenship:"
            components={{
              strong: <strong>_</strong>,
            }}
          />
        </span>
        <div className={styles.buttonsContainer + ' ' + styles.urgencyButtonsContainer}>
          <div
            className={
              styles.questionaryButton +
              (fieldsByName.urgency.value === 'immediately' ? ' ' + styles.active : '')
            }
            onClick={() => fieldsByName.urgency.setValue('immediately')}
          >
            {t('form_step_2.urgency.immediately_label', 'Immediately')}
            <FaCheck className={styles.checkIcon}></FaCheck>
          </div>
          <div
            className={
              styles.questionaryButton +
              (fieldsByName.urgency.value === 'withinAFewMonths' ? ' ' + styles.active : '')
            }
            onClick={() => fieldsByName.urgency.setValue('withinAFewMonths')}
          >
            {t('form_step_2.urgency.within_a_few_months_label', 'Within a few months')}
            <FaCheck className={styles.checkIcon}></FaCheck>
          </div>
          <div
            className={
              styles.questionaryButton +
              (fieldsByName.urgency.value === 'sometimeThisYear' ? ' ' + styles.active : '')
            }
            onClick={() => fieldsByName.urgency.setValue('sometimeThisYear')}
          >
            {t('form_step_2.urgency.sometime_this_year_label', 'Sometime this year')}
            <FaCheck className={styles.checkIcon}></FaCheck>
          </div>
        </div>
      </div>
      {renderSubmitButton({
        labels: {
          disabled: t('form_step_2.button.send', 'Confirm'),
          ready: t('form_step_2.button.send', 'Confirm'),
          submitting: t('form_step_2.button.sending', 'Sending'),
          submitted: t('form_step_2.button.sent', 'Sent!'),
        },
        btnClasses: {
          common: styles.submitButton,
          disabled: styles.formDisabled,
          ready: styles.formReady,
          submitting: styles.formSubmitting,
          submitted: styles.formSubmitted,
        },
        iconClasses: {
          common: 'fas',
          disabled: '',
          ready: '',
          submitting: 'fa-spinner fa-spin',
          submitted: 'fa-check',
        },
      })}
      {renderFormMessage({
        styles: {
          formMessage: styles.formMessage,
          formMessageSuccess: styles.formMessageSuccess,
          formMessageError: styles.formMessageError,
        },
      })}
    </form>
  );
};

export default FormStep2;
